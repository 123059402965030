let $filterItems,
    $searchInput,
    $sortInput,
    $resetFilters,
    $postsContainer,
    $filterContainer,
    $paginationContainer,
    $toggleFilters,
    $numPosts,
    $loaders,
    $pagination,
    $filterPopup,
    $toggleFilterPopup,
    $relatedPostContainer,
    $spinner,

    currentPage = 1,
    firstLoad = true,

    post_type,
    popup_status,
    sort_option,
    search_value,
    filters = [],
    queryString = window.location.search,
    urlParams = new URLSearchParams(queryString);

$(document).ready(function ($) {
    $postsContainer = $('.js-posts-container');
    $filterContainer = $('.js-filter-container');
    $paginationContainer = $('.js-pagination-container');
    $searchInput = $('.js-filter-search');
    $sortInput = $('.js-sort');
    $numPosts = $('.js-num-posts');
    $loaders = $('.js-loader');
    $relatedPostContainer = $('.js-related-posts');
    $spinner = $('.c-related-loader__spinner');

    getUrlParams();

    if ($postsContainer.length !== 0 && firstLoad) {
        post_type = $postsContainer.data('pt');

        // Set initial sort
        if(search_value === '' && $sortInput.val() === 'relevance_desc' ){
            sort_option = 'date_desc';
        }
        else{
            sort_option = $sortInput.val();
        }

        // Set inital search
        // search_value = $searchInput.val();
        initFunctions();
        getPosts();
        firstLoad = false;
    }

    $searchInput.on('keyup', function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            search()
        }
    });
});

function getPosts() {
    console.log(sort_option);
    $loaders.addClass('is_loading');
    $.ajax({
        url: linku_ajax_url,
        type: 'post',
        data: {
            action: 'load_posts',
            post_type: post_type,
            filters: filters,
            sort_option: sort_option,
            search_value: search_value,
            paged: currentPage,
            popup_status: popup_status
        },
        dataType: 'json',
        success: function (response) {
            $searchInput.val(search_value);
            $postsContainer.html(response.postsHtml);
            $filterContainer.html(response.filtersHtml);
            $paginationContainer.html(response.paginationHtml);
            setNumPosts(response.numPosts);
            if (typeof checkFavourites === "function") {
                checkFavourites();
            }
            $loaders.removeClass('is_loading');
            initFunctions();
            $('.real3dflipbook').each(function () {
                let fbdata = $(this).data("flipbook-options");
                $(this).flipBook({
                    pdfUrl: fbdata.pdfUrl
                });
            })
        },
        error: function (response) {
            console.log('error', response);
        }
    });
}


function initFunctions() {
    $filterItems = $('.js-filter-item');
    $toggleFilters = $('.js-toggle-filter');
    $resetFilters = $('.js-clear-filters');
    $pagination = $('.js-pagination');
    $toggleFilterPopup = $('.js-toggle-filter-popup');
    $filterPopup = $('.js-filter-popup');

    $filterItems.on('click', function () {
        const filter = $(this).data('filter');
        if ($(this)[0].type === 'radio') {
            filters = filters.filter(item => !(item.includes($(this)[0].name)));
        }
        if (filters.includes(filter)) {
            filters = filters.filter(item => item !== filter);
        } else {
            filters.push(filter);
        }

        popup_status = !!$filterPopup.hasClass('is-open');
        currentPage = 1;
        getPosts();
        updateUrl();
    });

    $resetFilters.on('click', function (e) {
        filters = [];
        getPosts();
        updateUrl();
    });


    $toggleFilters.on('click', function (e) {
        e.stopPropagation();
        if ($(this).hasClass('is-open')) {
            $(this).removeClass('is-open');
            $(this).next().slideUp();
        } else {
            $(this).addClass('is-open');
            $(this).next().slideDown();
        }
    });
    // $toggleFilters.each(function(){
    //     console.log("gevonden", $(this).find('.is_selected').length !== 0);
    //     if($(this).find('.is_selected').length !== 0){
    //         $(this).addClass('is_open');
    //         $(this).next().slideDown();
    //     }
    // });

    $pagination.on('click', function (e) {
        $('html, body').animate({
            scrollTop: $postsContainer.offset().top - 200
        }, 'slow');
        if ($(this).data().page === 'prev') {
            currentPage = currentPage - 1;
        } else if ($(this).data().page === 'next') {
            currentPage = currentPage + 1;
        } else {
            currentPage = Number($(this).data().page);
        }
        getPosts();
        updateUrl();
    });

    $toggleFilterPopup.on('click', function (e) {
        $filterPopup.each(function () {
            if ($(this).hasClass('is-open')) {
                $body.removeClass('menu-is-open');
                $(this).fadeOut().removeClass("is-open");
            } else {
                $(this).fadeIn().addClass("is-open");
                $body.addClass('menu-is-open');
            }
        });
    });
}


function sortPosts(selected) {
    sort_option = selected.value;
    getPosts();
}


function search() {
    search_value = $searchInput.val();
    if(search_value === ''){
        sort_option = 'date_desc';
    }
    else{
        sort_option = $sortInput.val();
    }
    currentPage = 1;
    updateUrl();
    getPosts();

}

function setNumPosts(num) {
    const singular = $numPosts.data('singular');
    const plural = $numPosts.data('plural');
    let newString = '';
    if (num === -1) {
        newString = `Vul een zoekopdracht in.`
    } else if (num === 0) {
        newString = `Geen ${plural} gevonden.`
    } else if (num === 1) {
        newString = `Eén ${singular} gevonden`
    } else {
        newString = `${num} ${plural} gevonden`
    }

    $numPosts.html(newString);

}

function getUrlParams() {
    let termFilters = [], posttypeFilters = [], metaFilters = [];
    if (urlParams.has('term')) {
        termFilters = urlParams.getAll('term');
        for (let i in termFilters) {
            termFilters[i] = 'term.' + termFilters[i];
        }
    }
    if (urlParams.has('posttype')) {
        posttypeFilters = urlParams.getAll('posttype');
        for (let i in posttypeFilters) {
            posttypeFilters[i] = 'posttype.' + posttypeFilters[i];
        }
    }
    if (urlParams.has('post_meta')) {
        metaFilters = urlParams.getAll('post_meta');
        for (let i in metaFilters) {
            metaFilters[i] = 'post_meta.' + metaFilters[i];
        }
    }
    filters = termFilters.concat(posttypeFilters, metaFilters)
    if (urlParams.has('page')) {
        currentPage = urlParams.get('page');
    }
    if (urlParams.has('s')) {
        search_value = urlParams.get('s');
    }
    else {
        search_value = '';
    }
}

function updateUrl() {
    urlParams.delete('term');
    urlParams.delete('posttype');
    urlParams.delete('post_meta');
    urlParams.delete('s');
    for (let i in filters) {
        let type = filters[i].split(".")[0];
        let value = filters[i].split(".").pop();
        urlParams.append(type, value);
    }
    if (search_value) {
        urlParams.set('s', search_value);
    }
    urlParams.set('page', currentPage);
    window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
}

function getRelatedPosts(currentPosttype, collectionPosttype, currentPostId) {
    $spinner.addClass('is-loading');
    $relatedPostContainer.css('display', 'none');
    $.ajax({
        url: linku_ajax_url,
        type: 'post',
        data: {
            action: 'get_collection',
            post_type: currentPosttype,
            post_id: currentPostId,
            collection_type: collectionPosttype.value
        },
        dataType: 'json',
        success: function (response) {
            $relatedPostContainer.html(response.collectionHtml);
            $spinner.removeClass('is-loading');
            $relatedPostContainer.css('display', 'flex');
        },
        error: function (response) {
            console.log('error', response);
        }
    });
}

function getItemsFromCollection(posttype, itemCollection) {
    $spinner.addClass('is-loading');
    $relatedPostContainer.css('display', 'none');
    console.log(itemCollection);
    $.ajax({
        url: linku_ajax_url,
        type: 'post',
        data: {
            action: 'get_items_from_collection',
            collection: itemCollection,
            posttype: posttype.value
        },
        dataType: 'json',
        success: function (response) {
            $relatedPostContainer.html(response.collectionHtml);
            $spinner.removeClass('is-loading');
            $relatedPostContainer.css('display', 'flex');
        },
        error: function (response) {
            console.log('error', response);
        }
    });
}
